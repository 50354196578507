.root {
  @apply px-3 py-2 px-2 h-[42px] text-base min-w-24 hover:opacity-80 bg-white border border-border hover:border-secondary m-1 inline-flex justify-center items-center gap-[2px] align-middle leading-normal;
  @apply 2xl:text-sm mxl:min-w-[5.2rem];
}
.active {
  @apply border-primary hover:border-primary shadow-[inset_0_0_0_0.5px_#046565];
  /*bg-tableRowHover*/
}
.btnPrimary {
  @apply text-white bg-[linear-gradient(180deg,rgba(4,101,101,0.85),#046565)] border-primary hover:border-primary;
}
.btnPrimary.isDisabled,.btnPrimary[disabled]{
  @apply text-white !opacity-50 !border-primary;
}
.active.isDisabled, .active[disabled]{
  @apply !border-black text-black;
}
.btnSecondary {
  @apply w-full !bg-secondary text-white !h-14 tracking-wider bg-[linear-gradient(180deg,rgba(74,74,74,0.85),"#4a4a4a")] border-secondary;
}
.btnIcon {
  @apply w-[52px] lg:w-12 md:w-10 h-[52px] lg:h-12 md:h-10 min-w-0 border-0;
}
.isDisabled,button[disabled] {
    @apply !opacity-100 text-disabled hover:border-border select-none cursor-not-allowed;
}
